const BASE_URL = process.env.BASE_URL;
const SECURED_GATEWAY_URL = BASE_URL
  ? BASE_URL + "/gateway-service"
  : window.location.protocol +
    "//" +
    window.location.hostname +
    "/gateway-service";
const SECURED_GRAPHQL_URL = SECURED_GATEWAY_URL + "/graphql";
const SECURED_REST_URL = SECURED_GATEWAY_URL + "/api/v1";
const RELEASE_VERSION = "v1.0.1";

// eslint-disable-next-line
console.log("BASE_URL", BASE_URL);
// eslint-disable-next-line
console.log("SECURED_GRAPHQL_URL", SECURED_GRAPHQL_URL);
// eslint-disable-next-line
console.log("SECURED_REST_URL", SECURED_REST_URL);
// eslint-disable-next-line
console.log("process.env.NODE_ENV", process.env.NODE_ENV);
// eslint-disable-next-line
console.log("process.env.ENVIRONMENT", process.env.ENVIRONMENT);

export const microfrontendLayout = {
  routes: [
    {
      type: "nav",
      routes: [
        {
          type: "application",
          name: "@datwyler/navbar-mfe",
          props: {
            SECURED_GATEWAY_URL: SECURED_GATEWAY_URL,
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
            RELEASE_VERSION: RELEASE_VERSION,
          },
        },
      ],
    },
    {
      type: "route",
      path: "device-monitoring",
      routes: [
        {
          type: "application",
          name: "@datwyler/device-monitoring-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
            REST_URL: SECURED_REST_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "site",
      routes: [
        {
          type: "application",
          name: "@datwyler/site-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
            REST_URL: SECURED_REST_URL,
            SECURED_GATEWAY_URL: SECURED_GATEWAY_URL,
          },
        },
      ],
    },
    {
      type: "route",
      default: true,
      routes: [
        {
          type: "application",
          name: "@datwyler/homepage-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "/user",
      routes: [
        {
          type: "application",
          name: "@datwyler/user-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
            SECURED_GATEWAY_URL: SECURED_GATEWAY_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "/alarm",
      routes: [
        {
          type: "application",
          name: "@datwyler/alarm-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "/visoai",
      routes: [
        {
          type: "application",
          name: "@datwyler/viso-ai-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "/tenant",
      routes: [
        {
          type: "application",
          name: "@datwyler/tenant-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
            SECURED_GATEWAY_URL: SECURED_GATEWAY_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "/telemetry",
      routes: [
        {
          type: "application",
          name: "@datwyler/telemetry-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "/analytics",
      routes: [
        {
          type: "application",
          name: "@datwyler/analytics-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
          },
        },
      ],
    },
    {
      type: "route",
      path: "/gateway",
      routes: [
        {
          type: "application",
          name: "@datwyler/gateway-mfe",
          props: {
            GRAPHQL_URL: SECURED_GRAPHQL_URL,
          },
        },
      ],
    },
  ],
};
